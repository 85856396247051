(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-selector-2023/assets/javascripts/draw-selector-table.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-selector-2023/assets/javascripts/draw-selector-table.js');

'use strict';

const {
  DrawCardContainer
} = svs.components.tipsen.drawCard;
const {
  DRAW_CARD_VARIANTS
} = svs.components.tipsen.drawCard.constants;
const {
  DrawSelectorHeader
} = svs.components.tipsen.drawSelector;
const {
  DrawSelectorLinkItem
} = svs.components.tipsen.drawSelector;
const DrawSelectorTable = _ref => {
  let {
    draws = [],
    selectedDrawNumber,
    onToggleDrawSelector,
    hasExtraMoneyValue,
    branding,
    sportTypes
  } = _ref;
  const drawSelectorClassNames = ['pg_draw_selector', 'pg_draw_selector_table'];
  branding && drawSelectorClassNames.push("pg_draw_selector--".concat(branding));
  return React.createElement("div", {
    className: drawSelectorClassNames.join(' '),
    role: "table"
  }, React.createElement(DrawSelectorHeader, {
    blockClassName: "pg_draw_selector_table",
    hasExtraMoneyValue: hasExtraMoneyValue,
    onToggleFunc: onToggleDrawSelector,
    sportTypes: sportTypes
  }), draws.map(draw => {
    const isSelected = draw.drawNumber === selectedDrawNumber;
    return React.createElement(DrawSelectorLinkItem, {
      betIndex: draw.betIndex,
      description: draw.description,
      drawComment: draw.comment,
      drawNumber: draw.drawNumber,
      isLinkItem: !isSelected,
      key: draw.drawNumber,
      onToggleDrawSelector: onToggleDrawSelector,
      productId: draw.productId,
      regCloseTime: draw.regCloseTime
    }, React.createElement(DrawCardContainer, {
      branding: branding,
      currentDraw: draw,
      drawCardVariant: DRAW_CARD_VARIANTS.TABLE_ROW,
      isSelected: isSelected
    }));
  }));
};
setGlobal('svs.components.tipsen.drawSelector.DrawSelectorTable', DrawSelectorTable);

 })(window);